import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import backgroundImageAvif from '../../images/pattern/aida-pattern-both.avif';
import backgroundImagePng from '../../images/pattern/aida-pattern-both.png';
import backgroundImageWebp from '../../images/pattern/aida-pattern-both.webp';
import { getLocalizedUrl, getSupportedImageFormat, registerGoogleEvent } from '../../shared/utils';
import * as classes from './Hero.module.scss';

const Hero = props => {
    const { title, description, button2Label, button2Link } = props;
    const { t } = useTranslation();
    const location = useLocation();
    const contactUrl = getLocalizedUrl('/support/contacts', location.pathname);
    const [ primaryAction ] = useState(<a href={contactUrl}
                                                            className="btn btn-primary btn-wide"
                                                            onClick={() => handleCallToActionClick('primary')}
                                                            id="hero-primary"
    >
        <FontAwesomeIcon icon="comments" />&nbsp;
        {t('common:contactUsLong')}
    </a>);

    useEffect(() => {
        const loadBackgroundImage = () => {
            const backgroundElement = document.querySelector(`.${classes.BackgroundImage}`);
            if (backgroundElement) {
                if (window.innerWidth < 1200) {
                    const format = getSupportedImageFormat();
                    let imageUrl;
                    switch (format) {
                        case 'avif':
                            imageUrl = backgroundImageAvif;
                            break;
                        case 'webp':
                            imageUrl = backgroundImageWebp;
                            break;
                        default:
                            imageUrl = backgroundImagePng;
                    }
                    backgroundElement.style.backgroundImage = `url(${imageUrl})`;
                }
                else {
                    backgroundElement.style.backgroundImage = 'none';
                }
            }
        };

        // Load image after a short delay to prioritize other content
        const timer = setTimeout(loadBackgroundImage, 100);

        // Add resize event listener
        window.addEventListener('resize', loadBackgroundImage);

        // Cleanup function
        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', loadBackgroundImage);
        };
    }, []);

    const handleCallToActionClick = source => {
        const label = `${source}_${location.pathname}`;
        registerGoogleEvent('click_callToAction', label);
        if (source === 'primary') {
            registerGoogleEvent('sign_up_intent');
        }

        return true;
    };

    const { language } = useI18next();

    return <div className={classes.Hero}>
        <div className={clsx(classes.Overlay, 'd-xl-none')} />
        <div className={clsx(classes.BackgroundImage, 'd-xl-none')} />
        <div className="container p-0" style={{ display: 'flex' }}>
            <div className={classes.Content}>
                <h1 className={classes.Title}>
                    {title}
                </h1>
                <h2 className={classes.Description}>
                    {description}
                </h2>
                <div className={classes.Buttons}>
                    {primaryAction}
                    <Link to={button2Link || '/platform/data-extraction'} className="btn btn-secondary btn-wide light"
                          id="hero-secondary"
                          onClick={() => handleCallToActionClick('secondary')}>
                        {button2Label ? button2Label : <Trans>Explore the features</Trans>}
                    </Link>
                </div>
            </div>
            <div className={clsx(classes.Image, 'd-none', 'd-xl-block')}>
                {language === 'en' && (
                    <StaticImage
                        src="./images/aida-hero-document-fields-relations-en.png"
                        alt="AIDA geometric pattern"
                        placeholder="blurred"
                        layout="fullWidth"
                    />
                )}
                {language === 'it' && (
                    <StaticImage
                        src="./images/aida-hero-document-fields-relations-it.png"
                        alt="AIDA geometric pattern"
                        placeholder="blurred"
                        layout="fullWidth"
                    />
                )}
                {language === 'es' && (
                    <StaticImage
                        src="./images/aida-hero-document-fields-relations-es.png"
                        alt="AIDA geometric pattern"
                        placeholder="blurred"
                        layout="fullWidth"
                    />
                )}
                {language === 'fr' && (
                    <StaticImage
                        src="./images/aida-hero-document-fields-relations-fr.png"
                        alt="AIDA geometric pattern"
                        placeholder="blurred"
                        layout="fullWidth"
                    />
                )}
                {language === 'pt' && (
                    <StaticImage
                        src="./images/aida-hero-document-fields-relations-pt.png"
                        alt="AIDA geometric pattern"
                        placeholder="blurred"
                        layout="fullWidth"
                    />
                )}
                {language === 'de' && (
                    <StaticImage
                        src="./images/aida-hero-document-fields-relations-de.png"
                        alt="AIDA geometric pattern"
                        placeholder="blurred"
                        layout="fullWidth"
                    />
                )}
            </div>
        </div>
    </div>;
};

Hero.propTypes = {
    title: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
    description: PropTypes.object.isRequired,
    button2Label: PropTypes.object,
    button2Link: PropTypes.string,
    paddingBottom: PropTypes.number
};

export default Hero;
