import React from 'react';

const PrivacyPolicyIt = () => {
    return <>
        <p>In questo documento vengono illustrate le modalità di gestione del sito all’indirizzo internet aidacloud.com
           (il
           “Sito”) in materia di tutela dei dati personali degli utenti.</p>
        <p>Questa informativa è resa ai sensi dell’articolo 13 del Decreto Legislativo 30 giugno 2003, n. 196 - Codice
           in materia di protezione dei dati personali.</p>
        <p>L'informativa si ispira anche alla Raccomandazione numero 2/2001 che le autorità europee per la protezione
           dei dati personali, riunite nel Gruppo istituito dall'articolo 29 della direttiva numero 95/46/CE, hanno
           adottato il 17 maggio 2001 per individuare alcuni requisiti minimi per la raccolta di dati personali on-line,
           e, in particolare, le modalità, i tempi e la natura delle informazioni che i titolari del trattamento devono
           fornire agli utenti quando questi si collegano a pagine web, indipendentemente dagli scopi del
           collegamento.</p>
        <p>L'informativa è resa anche per tutti i domini correlati o comunque facenti capo al Sito e non per altri siti
           web eventualmente consultati tramite link.</p>
        <p>Prendiamo molto sul serio la salvaguardia della Privacy dei nostri utenti e dedichiamo la massima priorità al
           fine di garantire la sicurezza e la riservatezza dei dati personali forniti dagli utenti. Per dati personali
           si intendono informazioni collegabili a una persona specifica, come per esempio nome, indirizzo, data di
           nascita, numero di telefono e/o indirizzo e-mail.</p>
        <p>Suggeriamo pertanto di prendere visione del presente documento per saperne di più sulle linee di condotta
           adottate in materia di tutela dei dati personali.</p>
        <h2>Il Titolare del Trattamento</h2>
        <p>Il titolare del trattamento dei dati personali degli utenti raccolti sul Sito è Technology & Cognition LAB
           SRL ("Technology & Cognition LAB") con sede in Corso Re Umberto, 10 – Torino, TO, Italia. Eventuali dati
           ulteriori che dovessero essere richiesti per l’utilizzo o l’acquisto di servizi o prodotti forniti da terzi,
           saranno raccolti da questi terzi.</p>
        <h2>Quali dati personali sono raccolti ed utilizzati</h2>
        <p>Technology & Cognition LAB raccoglie dati personali forniti specificamente e volontariamente dagli utenti del
           Sito.
           Su tutte le pagine del Sito che raccolgono dati personali, viene specificamente descritto quali dati sono
           necessari al fine di fornire il prodotto o servizio richiesto dell’utente.</p>
        <h2>Modalità di raccolta dei dati</h2>
        <h4>Registrazione degli utenti</h4>
        <p>Se l’utente si registra raccoglieremo informazioni personali che vengono fornite manualmente riempiendo il
           modulo di registrazione, quali nome, indirizzo e-mail, indirizzo postale, numero telefonico, preferenze, nome
           utente, password e informazioni per contattare altre persone.</p>
        <h4>Contatti</h4>
        <p>Vengono raccolti i dati personali dell’utente quali nome e cognome, indirizzo e-mail ogni volta che l’utente
           invia una domanda o un suggerimento o richiede informazioni o assistenza.</p>
        <h4>Altre Fonti</h4>
        <p>Qualora l'utente si colleghi al Sito da un sito gestito da uno dei nostri partner ("clicking-through"), e sia
           già registrato presso quel partner, potremmo ricevere alcuni dati forniti allo stesso. Consigliamo perciò
           all’utente di prendere visione della privacy policy presente sul sito del partner al fine di conoscere quali
           dati siano stati raccolti su quel sito e quale sia l’utilizzo consentito al partner. Potremmo anche non
           conservare queste informazioni; qualora decidessimo di conservarle, ci atterremo alla nostra dichiarazione
           sulla privacy, senza tener conto di quella fornita dal partner.</p>
        <p>Se l'utente si collega al Sito attraverso uno dei nostri partner (indipendentemente dalla sua registrazione
           presso il sito del partner) e decide di registrarsi sul Sito, potrebbe esserci richiesto di fornire a quel
           partner tutti o parte dei dati di registrazione. Forniremo tali informazioni solo in conformità alla nostra
           dichiarazione sulla privacy, ma non potremo controllare l'uso di tali dati da parte del nostro partner. In
           caso di dubbi in merito alla dichiarazione sulla privacy del nostro partner, l’utente dovrebbe prendere
           visione della dichiarazione prima di fornirci qualsivoglia informazione.</p>
        <h4 id="cookie">Cookies</h4>
        <p>I cookies sono dei piccoli file di testo conservati nel PC dell'utente. Il nostro sito utilizza sia i cookies
           di sessione ID (o temporanei) che quelli persistenti. I cookies di sessione ID svaniscono definitivamente al
           momento della chiusura del browser. I file cookie persistenti rimangono nel disco fisso del computer
           dell’utente finché l’utente non li elimina manualmente.
           Il Sito utilizza i cookies per fini diversi. Per esempio, i cookies vengono utilizzati per riconoscere
           automaticamente se l'utente si sia collegato in precedenza al Sito. I cookies vengono utilizzati anche per
           tracciare i percorsi di navigazione dell'utente e per capire meglio il modo in cui utilizza il Sito.</p>
        <p>La maggior parte dei navigatori internet accetta automaticamente i cookies, ma se si preferisce bloccarli
           basta riconfigurare le impostazioni di protezione del browser. Tuttavia, se si sceglie quest'ultima opzione,
           si incorre nella limitazione all'accesso di alcune parti del Sito.</p>
        <p>Quando l’utente visita il Sito, vengono raccolte informazioni sul computer e viene inviato al Computer
           dell’utente un Cookie. Questo dato non viene fornito dall’utente, ma raccolto automaticamente al fine di
           supportare la Sua visita al nostro Sito.</p>
        <p>Se l’utente non si registra al Sito, i dati personali da noi raccolti saranno limitati. Raccoglieremo
           l’indirizzo IP dell’utente al fine di individuare eventuali problemi collegati al nostro server, amministrare
           il Sito e compilare statistiche di utilizzo.</p>
        <p>Tuttavia, a meno che l’utente non si registri al Sito, il cookie non avrà accesso a informazioni che ci
           permetteranno di identificarlo. I cookie, infatti, non vengono utilizzati per acquisire informazioni presenti
           sull’hard disk e non possono raccogliere informazioni da altri cookie presenti sul computer dell'utente e
           provenienti da altri siti. Il ricorso ai cookie da parte nostra è finalizzato alla comprensione di come gli
           utenti utilizzino il nostro Sito e di come questo possa essere migliorato.</p>
        <p>In ogni caso sarà estremamente difficile identificare l’utente a partire dal solo indirizzo IP e non verrà
           fatto alcun tentativo in questo senso.
           Una volta che l’utente ha effettuato la registrazione al Sito, le informazioni personali dell’utente vengono
           associate al Cookie e rimangono nel computer dell’Utente finché non vengono eliminate manualmente.</p>
        <h4>LogFile di registro</h4>
        <p>Come la maggior parte dei server, utilizziamo dei log file. I log file includono gli indirizzi IP, il tipo di
           server, l'ISP, le pagine di riferimento e di uscita, il tipo di piattaforma, i dati temporali (data/ora), il
           numero di clic. Questi file servono ad analizzare le tendenze, amministrare il sito, tracciare il percorso di
           navigazione, diagnosticare i problemi di server e raccogliere informazioni demografiche generali sull'uso
           dell'aggregato IP. Gli indirizzi e tutti gli atri dati di registro non sono collegati ad informazioni
           identificabili di carattere personale.</p>
        <h2>Modalità di impiego dei Dati</h2>
        <p>I dati raccolti sul Sito possono essere impiegati per:</p>
        <h4>Comunicazioni commerciali</h4>
        <p>Come utente registrato del Sito, può ricevere da Technology & Cognition LAB periodicamente e-mail su serate
           ed eventi, informazioni su servizi o altre opportunità offerte.</p>
        <p>Al momento della registrazione, viene richiesto all'utente di fornire dati che permettano di contattarlo,
           quali nome ed indirizzo di posta elettronica. Per rispetto della privacy dei nostri utenti offriamo la
           possibilità di non ricevere queste comunicazioni commerciali. A questo effetto riferirsi alla sezione del
           sito "Unregister" (opt-out).</p>
        <h4>Risposte a domande o suggerimenti</h4>
        <p>Technology & Cognition LAB userà l’indirizzo e-mail dell’utente o numero di telefono o numero di fax per
           rispondere nel caso in cui l’utente invia una domanda o un suggerimento o richieda assistenza.</p>
        <p>Richiesta di aggiornamento o cancellazione dei dati:</p>
        <p>Quando l’utente richiede un aggiornamento o la cancellazione dei dati, Technology & Cognition LAB invierà una
           e-mail di richiesta di conferma.</p>
        <h4>Comunicazioni di servizio</h4>
        <p>Saltuariamente Technology & Cognition LAB può inviare comunicazione via e-mail prettamente di servizio, ad
           esempio, se il sito è temporaneamente sospeso per manutenzione.</p>
        <h4>Miglioramento della qualità</h4>
        <p>Technology & Cognition LAB raccoglie alcuni dati in base alla navigazione degli utenti nel Sito al fine di
           migliorare la comprensione e il servizio ai nostri utenti. Tali dati ci consentono di ottimizzare la capacità
           dell’utente di navigare nel Sito e di sviluppare programmi e contenuti personalizzati che interessino i
           nostri utenti registrati e i visitatori del nostro Sito. Non viene raccolto alcun dato personale, a meno che
           l’utente non lo fornisca esplicitamente.</p>
        <p>Questi dati possono essere usati anche per visualizzare messaggi pubblicitari mirati. Nessun dato personale
           viene usato per fornire messaggi pubblicitari mirati. Le informazioni demografiche aggregate vengono da noi
           condivise con i nostri partner e inserzionisti. Tali informazioni non sono collegate ad alcun dato
           personale.</p>
        <h4>Fornitori di servizi autorizzati</h4>
        <p>Technology & Cognition LAB condivide le informazioni personali degli utenti con fornitori di servizio
           autorizzati che portano a termine alcune funzioni o servizi per conto nostro. Per esempio, ricorriamo a
           società esterne per il controllo e la gestione del Sito, le statistiche. Ricorriamo a fornitori di servizio
           anche per rispondere agli ordini, procedere ad analisi di dati commerciali e fornire assistenza
           marketing.</p>
        <p>È possibile che Technology & Cognition LAB stipuli un contratto con una terza parte ritenuta affidabile al
           fine di agevolare la distribuzione di comunicazioni. </p>
        <h2>Diritti dell’Utente e accesso ai dati</h2>
        <p>Gli Utenti cui si riferiscono i dati personali hanno il diritto in qualunque momento di ottenere la conferma
           dell'esistenza o meno dei medesimi dati e di conoscerne il contenuto e l'origine, verificarne l'esattezza o
           chiederne l'integrazione o l'aggiornamento, oppure la rettificazione (art. 7 del d.lgs. n. 196/2003).</p>
        <p>Ai sensi del medesimo articolo l’utente ha il diritto di chiedere la cancellazione, la trasformazione in
           forma anonima o il blocco dei dati trattati in violazione di legge, nonché di opporsi in ogni caso, per
           motivi legittimi, al loro trattamento.</p>
        <p>Le richieste vanno rivolte a Technology & Cognition LAB via e-mail all’indirizzo indicato nella sezione “come
           contattarci“</p>
        <h2>Sicurezza delle informazioni</h2>
        <p>Prendiamo tutte le dovute precauzioni per proteggere i dati personali dei nostri utenti. Solo gli impiegati
           che hanno bisogno di tali dati per un determinato lavoro hanno accesso alle informazioni personali. Infine, i
           server che immagazzinano i dati a carattere personale si trovano in una stanza chiusa a chiave, protetti da
           password e firewall conformi agli standard dell’industria.</p>
        <h2>Modifiche alla presente Informativa sulla privacy</h2>
        <p>La presente Informativa sulla privacy è stata elaborata e inserita nel Sito il 18 marzo 2019.</p>
        <p>Indipendentemente da aggiornamenti successivi, rispetteremo le prassi in materia di riservatezza dei dati
           descritte nella presente Informativa sulla privacy nel momento in cui l’utente ci ha fornito i Suoi dati.</p>
        <p>Qualora decidessimo di modificare la nostra politica di garanzia della privacy, tali modifiche saranno
           riportate nella presente dichiarazione sulla privacy e alla data effettiva di applicazione, in modo che sia
           sempre possibile essere aggiornati sulle informazioni che raccogliamo, sull'uso che ne facciamo e, in alcuni
           casi, sulla loro eventuale diffusione. In caso di modifiche sostanziali alla presente informativa o al modo
           in cui Technology & Cognition LAB utilizza i dati personali degli utenti, Technology & Cognition LAB
           provvederà a darne adeguatamente e ampiamente notifica, prima di procedere alla loro attuazione.</p>
        <h2>Come contattarci</h2>
        <p>Per qualsiasi domanda o suggerimento sulla nostra dichiarazione sulla privacy, gli utenti possono contattarci
           all'indirizzo: privacy@tclab.it</p>
    </>;
};

export default PrivacyPolicyIt;
