import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as classes from './Cover.module.scss';

const Cover = props => {
    const { title, subtitle, cover, localizeCover = false } = props;
    const { t } = useTranslation();
    const { language } = useI18next();
    const [ actions ] = useState(
        <Link to="/support/contacts" className="btn btn-primary btn-wide" id="call-to-action-secondary">
            {t('common:contactUsLong')}
        </Link>
    );

    const data = useStaticQuery(graphql`
      query {
        allFile(filter: { relativePath: { regex: "/aida-cover-/" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData(height: 800, placeholder: BLURRED, quality: 90)
            }
            relativePath
          }
        }
      }
    `);

    const suffix = localizeCover ? `-${language}` : '';
    const fileName = `aida-cover-${cover}${suffix}`;
    const imageNode = data.allFile.nodes.find(node => node.relativePath.indexOf(`covers/${fileName}`) === 0);
    const backgroundImage = `url('${getSrc(imageNode)}')`;

    return <div className={classes.Cover} style={{ backgroundImage }}>
        <div className={clsx(classes.Overlay, 'd-xl-none')} />
        <div className={clsx(classes.BackgroundImage, 'd-xl-none')} style={{ backgroundImage }} />
        <div className="container p-0" style={{ display: 'flex' }}>
            <div className={classes.Content}>
                <h1>{title}</h1>
                {subtitle && <h2>{subtitle}</h2>}
                <div className={classes.Buttons}>
                    {actions}
                </div>
            </div>
        </div>
    </div>;
};

Cover.propTypes = {
    title: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
    subtitle: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
    cover: PropTypes.string.isRequired,
    localizeCover: PropTypes.bool
};

export default Cover;
