import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useImmer } from 'use-immer';
import AppGalleryLogo from '../components/AppGalleryLogo/AppGalleryLogo';
import AppStoreLogo from '../components/AppStoreLogo/AppStoreLogo';
import { Seo } from '../components/Seo';
import BillingToggle from '../components/UI/BillingToggle/BillingToggle';
import { OutboundLink } from '../components/UI/OutboundLink/OutboundLink';
import UserTypeSelector from '../components/UI/UserTypeSelector/UserTypeSelector';
import Layout from '../layouts/layout';
import { getLocalizedUrl, report_app_store_click, report_plan_click } from '../shared/utils';

import * as classes from './pricing.module.scss';

const PricingPage = props => {
    const { language, t } = useI18next();
    const [ billing, setBilling ] = useState('year');
    const [ userType, setUserType ] = useState('individual');
    const location = props.location.pathname;
    const [ featuresShown, setFeaturesShown ] = useImmer([]);

    const planResponsiveClasses = clsx(classes.PlanResponsive, 'col-lg-3 col-md-12');

    const getTitleEntry = plan => {
        return <div className={classes.Entry}>
            <strong>{t('pricing-page:everythingPlus', { plan })}</strong>
        </div>;
    };

    const getLimits = (plan, pages, storage) => {
        let label = t('pricing-page:priceCallToAction');
        if (plan !== 'starter') {
            label = t('pricing-page:priceCallToActionPay');
        }

        let buttonComponent;

        if (plan === 'enterprise_plus') {
            buttonComponent = <Link
                className={clsx('btn btn-primary loginClick')}
                to="/support/enterprise"
                onClick={() => report_plan_click('enterprise', language)}
            >
                {t('common:contactUsLong')}
            </Link>;
        }
        else if (plan === 'starter') {
            buttonComponent = <a
                className={clsx('btn btn-primary loginClick')}
                href={`/app/auth/register?from=${props.location.pathname}`}
            >
                {label}
            </a>;
        }
        else {
            buttonComponent = <a
                className={clsx('btn btn-primary loginClick')}
                href={`/app/auth/register?plan=${plan}&billing=${billing}&from=${props.location.pathname}`}
            >
                {label}
            </a>;
        }
        return <div className={clsx(classes.Feature, classes.Limits)}>
            <div><strong><Trans>Pages / month</Trans></strong></div>
            <div>{pages}</div>
            <div><strong><Trans>Document management</Trans></strong></div>
            <div>{storage}</div>
            {buttonComponent}
        </div>;
    };

    const getEntry = (content, url) => {
        let main = content;
        if (!!url) {
            main = <a href={getLocalizedUrl(url, location)} target="_blank" rel="noopener">{content}</a>;
        }

        return <div className={classes.Entry}>
            <FontAwesomeIcon icon="check" />
            <div>
                {main}
            </div>
        </div>;
    };

    const handleToggleFeatures = id => {
        setFeaturesShown(draft => {
            if (draft.indexOf(id) >= 0) {
                // Remove
                draft = draft.filter(e => e !== id);
            }
            else {
                // Add
                draft.push(id);
            }

            return draft;
        });
    };

    const getPlan = (id, name, priceObject, pages, ecm, features, support, isSuggested = false) => {
        let priceComponent;
        if (id === 'starter') {
            priceComponent = <>
                <span className={classes.Price}>{t('pricing-page:Free')}</span>
                <Trans>forever</Trans>
            </>;
        }
        else if (id === 'enterprise_plus') {
            priceComponent = <>
                <div className={classes.Price}><Trans>Let's build</Trans></div>
                <Trans>Your custom solution</Trans>
            </>;
        }
        else {
            const { month, year } = priceObject;
            const monthString = new Intl.NumberFormat(language || 'en-GB', {
                style: 'currency',
                currency: 'EUR'
            }).format(month);
            const yearString = new Intl.NumberFormat(language || 'en-GB', {
                style: 'currency',
                currency: 'EUR'
            }).format(year);

            priceComponent = <>
                {billing === 'year' && <>
                                        <span className={classes.Price}>
                                            <div className={classes.Muted}>{monthString}</div>
                                            {yearString}&nbsp;
                                        </span>
                    {t('pricing-page:perMonthAnnually')}
                </>}
                {billing === 'month' && <>
                                        <span
                                            className={classes.Price}>{monthString}&nbsp;</span>{t('pricing-page:perMonth')}
                </>}
            </>;
        }

        const shown = featuresShown.includes(id);

        let pagesString;
        if (isNaN(pages)) {
            pagesString = pages;
        }
        else {
            pagesString = new Intl.NumberFormat(language || 'en-GB').format(pages);
        }

        return <div className={planResponsiveClasses}>
            <div className={clsx(classes.Plan, isSuggested && classes.Suggested)}>
                {isSuggested && <div className={classes.SuggestedTitle}>
                    <Trans>Most popular</Trans>
                </div>}
                <div className={classes.Banner}>{name}</div>
                <div className={classes.Head}>
                    <div className={classes.Main}>
                        <div className={classes.Top}>
                            {priceComponent}
                        </div>
                    </div>
                    {getLimits(id, pagesString, ecm)}
                </div>
                <div className={clsx(classes.Toggle, shown && classes.Shown)} onClick={() => handleToggleFeatures(id)}>
                    <div className={classes.Border}></div>
                    {shown && <>
                        <Trans>Hide features</Trans>&nbsp;
                        <FontAwesomeIcon icon={[ 'fal', 'minus-circle' ]} />
                    </>}
                    {!shown && <>
                        <Trans>Show features</Trans>&nbsp;
                        <FontAwesomeIcon icon={[ 'fal', 'plus-circle' ]} />
                    </>}
                </div>
                <div className={clsx(classes.Feature, classes.List, shown && classes.Shown)}>
                    {features}
                </div>
                <div className={clsx(classes.FeatureSpacer, shown && classes.Shown)}></div>
                <div className={clsx(classes.Feature, classes.Support, shown && classes.Shown)}>
                    <div className={classes.Title}><Trans>Support</Trans></div>
                    <div className={classes.Subtitle}>{support}</div>
                </div>
            </div>
        </div>;
    };

    const count = '4';

    return <Layout location={props.location}>
        <div className={clsx(classes.Plans, 'container p-0')}>
            <div className={classes.Header}>
                <h1><Trans>Choose your AIDA plan</Trans></h1>
                <div>
                    <div className={classes.Item}>
                        <FontAwesomeIcon icon="check" />
                        <Trans i18nKey="pricing-page:bullets.1"><strong>Free forever</strong>: enjoy essential AIDA
                                                                                             features without time
                                                                                             limits or fees,
                                                                                             indefinitely.</Trans>
                    </div>
                    <div className={classes.Item}>
                        <FontAwesomeIcon icon="check" />
                        <Trans i18nKey="pricing-page:bullets.2"><strong>Flexible plans</strong>: tailored subscriptions
                                                                                               for everyone, from
                                                                                               individuals to
                                                                                               enterprises.</Trans>
                    </div>
                    <div className={classes.Item}>
                        <FontAwesomeIcon icon="check" />
                        <Trans i18nKey="pricing-page:bullets.3"><strong>Easy cancellation</strong>: cancel your plan
                                                                                                  anytime, without
                                                                                                  penalties or
                                                                                                  hassle.</Trans>
                    </div>
                    <div className={classes.Item}>
                        <FontAwesomeIcon icon="check" />
                        <Trans i18nKey="pricing-page:bullets.4"><strong>Smooth transitions</strong>: upgrade or
                                                                                                   downgrade your plan
                                                                                                   with ease to fit your
                                                                                                   needs.</Trans>
                    </div>
                </div>
            </div>
            <div className={classes.SelectorContainer}>
                <UserTypeSelector onChange={setUserType} value={userType} />
                <div className={classes.BillingSelectorContainer}>
                    <BillingToggle onChange={setBilling} value={billing} />
                </div>
            </div>

            <div className={clsx(classes.PlansContainer, 'row', userType === 'business' && classes.Business)}>
                {userType === 'individual' && <>
                    {getPlan('starter', 'Starter', null, 20, '100GB', <>
                            {getEntry(<><Trans>Data extraction (IDP)</Trans></>, '/platform/data-extraction')}
                            {getEntry(<><Trans>Document
                                               management</Trans>&nbsp;(ECM)</>, '/platform/archive-document-management')}
                            {getEntry(<><Trans>Document tasks</Trans></>)}
                            {getEntry(<>AIDA-GPT</>, '/platform/aida-gpt')}
                            {getEntry(<><Trans>Custom properties</Trans></>, '/platform/data-extraction')}
                            {getEntry(<><Trans>Hybrid AI</Trans></>, '/platform/data-extraction')}
                            {getEntry(<><Trans i18nKey="pricing-page:documentTypes" count="4">
                                <span>Configure up to <strong>{count}</strong> completely customizable document types</span>
                            </Trans></>)}
                            {getEntry(<><Trans>Barcode and QRCode
                                               extraction</Trans></>, '/platform/data-extraction#barcodes-qrcodes')}
                        </>,
                        <Trans>Self-service</Trans>)
                    }
                    {getPlan('personal', 'Personal', { month: 3.99, year: 3.25 }, 40, <Trans>Unlimited</Trans>, <>
                            {getTitleEntry('Starter')}
                            {getEntry(<Trans>Automatic file
                                             rename</Trans>, '/platform/data-extraction#automaticArchive')}
                            {getEntry(<><Trans>All pages of the document analyzed</Trans></>)}
                            {getEntry(<><Trans>Automatic split</Trans></>, '/platform/data-extraction#split')}
                            {getEntry(<><Trans>Base graph</Trans></>)}
                        </>,
                        <Trans>Self-service</Trans>)
                    }
                    {getPlan('professional', 'Professional', { month: 12.90, year: 10.75 }, 80,
                        <Trans>Unlimited</Trans>, <>
                            {getTitleEntry('Personal')}
                            {getEntry(<Trans
                                i18nKey="pricing-page:unlimitedDocumentTypes"><strong>Unlimited</strong> document
                                                                                                         types</Trans>)}
                            {getEntry(<Trans i18nKey="pricing-page:allInputs"><strong>All</strong> inputs</Trans>)}
                            {getEntry(<><strong>1</strong>&nbsp;
                                <Trans>destination</Trans></>, '/platform/integrations')}
                            {getEntry(<Trans>Automatic folders
                                             creation</Trans>, '/platform/data-extraction#automaticArchive')}
                            {getEntry(<Trans i18nKey="pricing-page:combinedDocumentHistory">Combined document
                                                                                            history</Trans>, '/platform/data-extraction#history')}
                        </>,
                        <Trans>Self-service</Trans>, true)
                    }
                    {getPlan('professional_plus', 'Professional Plus', { month: 34.90, year: 29.08 }, 200,
                        <Trans>Unlimited</Trans>, <>
                            {getTitleEntry('Professional')}
                            {getEntry(<Trans i18nKey="pricing-page:unlimitedDestinations"><strong>Unlimited</strong>destinations</Trans>, '/platform/integrations')}
                            {getEntry(<Trans>Lookup to integrate external
                                             data</Trans>, '/platform/data-extraction#lookup')}
                            {getEntry(<Trans
                                i18nKey="pricing-page:aidaPay"><span>Automate reception of payments with <strong>AIDA Pay</strong></span>AIDA
                                                                                                                                         Pay</Trans>)}
                            {getEntry(<><Trans>Advanced graph</Trans></>)}
                            {getEntry(<><Trans>Signatures detection</Trans></>)}
                        </>,
                        <Trans>Self-service</Trans>)
                    }
                </>}
                {userType === 'business' && <>
                    {getPlan('business', 'Business', { month: 54.90, year: 45.75 }, 400,
                        <Trans>Unlimited</Trans>, <>
                            {getTitleEntry('Professional')}
                            {getEntry(<Trans>Automatic anomalies detection and
                                             notifications</Trans>, '/platform/data-extraction#lookup')}
                            {getEntry(<Trans>Graph database to manage relations between
                                             documents</Trans>, '/platform/relations-knowledge-graph')}
                        </>,
                        <Trans>Basic</Trans>)
                    }
                    {getPlan('business_plus', 'Business Plus', { month: 99.00, year: 82.50 }, 800,
                        <Trans>Unlimited</Trans>, <>
                            {getTitleEntry('Business')}
                            {getEntry(<Trans i18nKey="pricing-page:users" count="5"><span>Up to <strong>{count}</strong> users</span></Trans>)}
                            {getEntry(<Trans>Table data extraction</Trans>, '/platform/data-extraction#table')}
                            {getEntry(<Trans>AIDA Team document workflows</Trans>)}
                        </>,
                        <Trans>Basic</Trans>, true)
                    }
                    {getPlan('enterprise', 'Enterprise', { month: 159.00, year: 140.83 }, 1600,
                        <Trans>Unlimited</Trans>, <>
                            {getTitleEntry('Business Plus')}
                            {getEntry(<Trans
                                i18nKey="pricing-page:unlimitedUsers"><strong>Unlimited</strong> users</Trans>)}
                            {getEntry(<Trans>Statistics for reporting</Trans>)}
                            {getEntry(<Trans>Unlimited graphs</Trans>)}
                        </>,
                        <Trans>Basic</Trans>)
                    }
                    {getPlan('enterprise_plus', 'Enterprise Plus', null, <Trans>Custom volume</Trans>,
                        <Trans>Unlimited</Trans>, <>
                            {getTitleEntry('Enterprise')}
                            {getEntry(<Trans>Advanced workflows</Trans>)}
                            {getEntry(<Trans>Duplicates detection</Trans>)}
                        </>,
                        <Trans>Priority, Consultancy, Hardware/Software and
                               Professional
                               Service</Trans>)
                    }
                </>}
            </div>
            <div className={classes.Message}>
                {t('pricing-page:registerNow')}
                <div className={classes.Subtitle}>
                     <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon={'ban'} transform="grow-9" color="#CCCCCC" />
                        <FontAwesomeIcon icon={'credit-card'} transform="shrink-5" />
                     </span>
                    <Trans>no credit card is required</Trans>
                </div>
                <div className={classes.StoresTitle}><Trans>You can also find it on</Trans></div>
                <div className={classes.Stores}>
                    <AppStoreLogo />
                    <AppGalleryLogo />
                    <OutboundLink className={classes.AppStore} href="https://s.tclab.it/gsuite-aida"
                                  title="AIDA on G Suite Marketplace"
                                  target="_blank" onClick={() => report_app_store_click('G Suite Marketplace')}
                                  rel="noopener">
                        <StaticImage
                            src="../images/partners/tclab-aida-gsuite-marketplace.png"
                            alt="Google GSuite marketplace logo"
                            width={246}
                            height={39}
                            placeholder="none"
                        />
                    </OutboundLink>
                    <OutboundLink className={clsx(classes.AppStore, classes.AwsMarketplace)}
                                  title="AIDA on AWS Marketplace"
                                  href="https://s.tclab.it/awsit-aida" target="_blank" rel="noopener"
                                  onClick={() => report_app_store_click('AWS Marketplace it')}>
                        <StaticImage
                            src="../images/partners/tclab-aida-aws-marketplace.png"
                            alt="AWS marketplace logo"
                            width={240}
                            height={29}
                            placeholder="none"
                        />
                    </OutboundLink>
                </div>
                <div className={classes.Notes}>
                    *&nbsp;{t('pricing-page:saveDescription')}
                </div>
            </div>
        </div>
        ;
    </Layout>
        ;
};

PricingPage.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired
};

export default PricingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "pricing-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'pricing-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Plans and pricing';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};

